import { useContext, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import {
  FieldSearchBar,
  ListVehicles,
  LoadVehicle,
  StatusBarTabs,
} from "components/app";
import { LoadingSpinner } from "components/Loading/LoadingSpinner";
import { NoPermissionMessage } from "components/NoPermissionMessage";
import { Breadcrumbs, Pagination } from "components/shared";

import { AuthContext } from "context/AuthContext";
import { SearchContext } from "context/SearchContext";

import {
  ALL,
  AWAITING,
  BUYNOW,
  DEFAULT_ITEMS_PER_PAGE,
  DRAFT,
  FLOW_TYPE,
  PENDING,
  REVIEW,
  STATUS_LIST,
  StorageKeys,
  WHOLESALE_DEALERSHIP_MENU,
} from "utils";
import { getPageTitle, SECTIONS } from "utils/pageSections";
import { PermissionAction, PermissionCategory } from "utils/userPermissions";
import "./styles.scss";

import { IVehicleDetail, NavigationMenuLabel } from "types";

import { InspectionService, ValuationService } from "api/client";

import { useSessionStorage } from "hooks";
import { useListQuery } from "hooks/useListQuery";

export const WholesaleDealershipList = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useSessionStorage(
    StorageKeys.WholesaleDealershipValuationList,
    1
  );
  const [scrollPosition, setScrollPosition] = useSessionStorage(
    StorageKeys.WholesaleDealershipValuationScroll,
    0
  );
  const [currentStatus, setCurrentStatus] = useState<string | number>(ALL.key);
  const { searchTxt } = useContext(SearchContext);
  const { hasPermission } = useContext(AuthContext);

  useEffect(() => {
    if (searchTxt && currentPage !== 1) {
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTxt]);

  const newStatus =
    currentStatus === AWAITING.key ? PENDING.key : currentStatus;

  const canViewDirectOffer = hasPermission(
    PermissionCategory.DIRECT_OFFERS,
    PermissionAction.VIEW
  );

  const canView =
    hasPermission(PermissionCategory.WHOLESALE_INSPECTIONS, [
      PermissionAction.VIEW,
      PermissionAction.VIEW_ANY,
    ]) &&
    // DIRECT OFFER
    (currentStatus !== BUYNOW.key ||
      (currentStatus === BUYNOW.key && canViewDirectOffer));

  const statusList = useMemo(
    () =>
      canViewDirectOffer
        ? STATUS_LIST.WholesaleDealership
        : STATUS_LIST.WholesaleDealership.map((option) =>
            option.key === BUYNOW.key ? { ...option, disabled: true } : option
          ),
    [canViewDirectOffer]
  );

  const {
    data: retailQueryData,
    isRefetching: retailQueryRefetching,
    isInitialLoading: retailQueryInitialLoading,
    isError: retailQueryError,
  } = useListQuery(
    {
      queryKey: StorageKeys.WholesaleDealershipValuationList,
      retailed: false,
      withParams: "requester,images,valuations,owner",
      status: currentStatus !== ALL.key ? currentStatus : "",
      search: searchTxt,
      currentPage: currentPage,
      scrollPosition: scrollPosition,
    },
    InspectionService.getVehicleList,
    {
      enabled:
        (currentStatus === ALL.key ||
          currentStatus === AWAITING.key ||
          currentStatus === REVIEW.key ||
          currentStatus === DRAFT.key) &&
        hasPermission(PermissionCategory.WHOLESALE_INSPECTIONS, [
          PermissionAction.VIEW,
          PermissionAction.VIEW_ANY,
        ]),
    }
  );

  const {
    data: wholesaleQueryData,
    isRefetching: wholesaleQueryRefetching,
    isInitialLoading: wholesaleQueryInitialLoading,
    isError: wholesaleQueryError,
  } = useListQuery(
    {
      queryKey: StorageKeys.WholesaleDealershipExternalValuationList,
      withParams: "requester,images,valuations,owner,seller",
      status: currentStatus !== ALL.key ? newStatus : "",
      search: searchTxt,
      currentPage: currentPage,
      scrollPosition: scrollPosition,
    },
    ValuationService.getValuationRequests,
    {
      enabled:
        (currentStatus === ALL.key ||
          currentStatus === AWAITING.key ||
          currentStatus === BUYNOW.key) &&
        canView,
    }
  );

  const combinedData = useMemo(() => {
    let currentData = [] as IVehicleDetail[];
    let total = 0;

    const retailQueryDisplayData = retailQueryError
      ? []
      : retailQueryData?.data ?? [];

    const wholesaleQueryDisplayData = wholesaleQueryError
      ? []
      : wholesaleQueryData?.data ?? [];

    const retailQueryTotal = retailQueryData?.meta?.total ?? 0;
    const wholesaleQueryTotal = wholesaleQueryData?.meta?.total ?? 0;

    if (!retailQueryInitialLoading && !wholesaleQueryInitialLoading) {
      if (currentStatus === ALL.key || currentStatus === AWAITING.key) {
        currentData = [...retailQueryDisplayData, ...wholesaleQueryDisplayData];
        total = retailQueryTotal + wholesaleQueryTotal;
      } else if (
        currentStatus === PENDING.key ||
        currentStatus === BUYNOW.key
      ) {
        currentData = [...wholesaleQueryDisplayData];
        total = wholesaleQueryTotal;
      } else {
        currentData = [...retailQueryDisplayData];
        total = retailQueryTotal;
      }
    }

    return {
      data: currentData?.sort((a, b) => {
        const date1 = new Date(a?.created_at?.replace("-", ""));
        const date2 = new Date(b?.created_at?.replace("-", ""));

        return date2.getTime() - date1.getTime();
      }),
      total,
    };
  }, [
    currentStatus,
    retailQueryData,
    retailQueryInitialLoading,
    wholesaleQueryData,
    wholesaleQueryInitialLoading,
    retailQueryError,
    wholesaleQueryError,
  ]);

  const handleFetchNext = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleFetchPrev = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleFetchSelected = (index: number) => {
    setCurrentPage(index);
  };

  const handleChangeStatus = (status: string | number) => {
    setCurrentStatus(status);
    setCurrentPage(1);
  };

  const handleGoSummary = (
    id: string,
    isExternal: boolean,
    requestId?: string
  ) => {
    setScrollPosition(
      `${document.getElementsByClassName("dashboard")[0].scrollTop}`
    );

    isExternal
      ? navigate(`valuation/${id}/${requestId}/summary`)
      : navigate(`valuation/${id}/summary`);
  };

  const getContent = () => {
    if (!canView)
      return (
        <NoPermissionMessage
          viewPermissionCategory={PermissionCategory.RETAIL_INSPECTIONS}
        />
      );

    if (retailQueryInitialLoading || wholesaleQueryInitialLoading)
      return <LoadVehicle />;

    if (wholesaleQueryError && retailQueryError)
      return (
        <div className="dashboard__content--empty">
          An error has occurred. Please, try again.
        </div>
      );

    if (!combinedData?.data?.length)
      return (
        <div className="dashboard__content--empty">
          No Inspections Created Yet
        </div>
      );

    return (
      <ListVehicles
        vehicles={combinedData.data}
        onSelect={handleGoSummary}
        flow={FLOW_TYPE.WHOLESALE_DEALERSHIP}
      />
    );
  };

  return (
    <>
      <Helmet>
        <title>{getPageTitle("Unvalued", SECTIONS.WHOLESALE_DEALERSHIP)}</title>
      </Helmet>
      <div className="dashboard__container">
        <div className="flex w-full gap-5">
          <div className="flex flex-col gap-2.5 pt-2 w-430">
            <Breadcrumbs
              paths={[
                {
                  title: NavigationMenuLabel.WHOLESALE_DEALERSHIP,
                  route: WHOLESALE_DEALERSHIP_MENU[0].route,
                },
                {
                  title: "Unvalued",
                  route: WHOLESALE_DEALERSHIP_MENU[0].route,
                },
              ]}
            />
            <div className="flex items-center gap-2">
              <div className="title">Unvalued</div>
              {retailQueryRefetching && wholesaleQueryRefetching && (
                <LoadingSpinner />
              )}
            </div>
            <div className="flex flex-row gap-5 items-center">
              {canView && (
                <Pagination
                  currentPage={currentPage}
                  pageLimit={DEFAULT_ITEMS_PER_PAGE}
                  total={combinedData.total}
                  onNextPage={handleFetchNext}
                  onPrevPage={handleFetchPrev}
                  onIndexedPage={handleFetchSelected}
                />
              )}
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <FieldSearchBar />
            <StatusBarTabs
              currentStatus={currentStatus}
              options={statusList}
              onSelect={handleChangeStatus}
            />
          </div>
        </div>
        <div className="dashboard__content">{getContent()}</div>
        <div className="dashboard__footer">
          {canView && (
            <Pagination
              currentPage={currentPage}
              pageLimit={DEFAULT_ITEMS_PER_PAGE}
              total={combinedData.total}
              onNextPage={handleFetchNext}
              onPrevPage={handleFetchPrev}
              onIndexedPage={handleFetchSelected}
            />
          )}
        </div>
      </div>
    </>
  );
};
