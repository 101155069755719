import { useMutation } from "@tanstack/react-query";
import classNames from "classnames";
import { ContractType } from "enums/ContractType";
import { useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  FieldMotorIntelValuation,
  FieldValuation,
  ModalConfirm,
  ModalInternalValuation,
  ModalMotorIntelLog,
  ModalOfferAdd,
  ModalOfferLog,
  ModalValuationAdd,
  ModalWholesaleValuations,
} from "components/app";
import { LinkButton } from "components/Button";
import { PricingInsightsModal } from "components/Modal/PricingInsightsModal";
import { Modal } from "components/shared";

import { AuthContext } from "context/AuthContext";

import {
  ACCEPTED,
  ACQUIRED,
  DECLINED,
  DECLINED_VALUATION,
  DISABLED_STATE_COLOR,
  FLOW_TYPE,
  formatNumber,
  STATUS_LIST,
  VALUATION_INVISIBLE_STATUSES,
} from "utils";
import { isValuationContractType } from "utils/inspections";
import { PermissionAction, PermissionCategory } from "utils/userPermissions";

import { IVehicleSummary } from "types";

import { NegotiationService, ValuationService } from "api/client";

import "./styles.scss";

interface ValuationsSectionProps {
  data: IVehicleSummary;
  flow?: FLOW_TYPE;
  hideComment?: boolean;
  canRevertVehicle?: boolean;
  onRefresh: () => void;
}

export const ValuationsSection = ({
  data,
  flow,
  canRevertVehicle = true,
  onRefresh,
}: ValuationsSectionProps) => {
  const { hasPermission } = useContext(AuthContext);
  const navigate = useNavigate();

  const [showOfferModal, setShowOfferModal] = useState<boolean>(false);
  const [showInternalModal, setShowInternalModal] = useState<boolean>(false);
  const [showWholesaleModal, setShowWholesaleModal] = useState<boolean>(false);
  const [showMpLogModal, setShowMpLogModal] = useState<boolean>(false);
  // const [showResearchVehicle, setShowResearchVehicle] =
  //   useState<boolean>(false);
  const [showValuationAddModal, setShowValuationAddModal] =
    useState<boolean>(false);
  const [showOfferAddModal, setShowOfferAddModal] = useState<boolean>(false);
  const [showBackToNegotiatingModal, setBackToNegotiatingModal] =
    useState<boolean>(false);
  const [showPricingInsightsModal, setShowPricingInsightsModal] =
    useState<boolean>(false);

  const {
    contract_id,
    status,
    valuations,
    valuation_requests,
    request_id,
    status: { name },
  } = data;

  const offerAmount = valuations.internal?.offer
    ? `$${formatNumber(valuations.internal?.offer?.amount)}`
    : "";

  const valuationAmount = valuations.internal?.valuation?.amount
    ? `$${formatNumber(valuations.internal?.valuation?.amount)}`
    : "";

  const hubOfferAmount = valuations.hub?.offer
    ? `$${formatNumber(valuations.hub?.offer?.amount)}`
    : "";

  const {
    mutate: handleRequestMPValuation,
    isLoading: isRequestingMPValuation,
    isSuccess,
  } = useMutation(
    (payload: { type: string }) =>
      ValuationService.requestMPValuation(contract_id, payload),
    {
      onSuccess: () => {
        toast.success("Valuation Requested", {
          autoClose: 2000,
          theme: "colored",
        });
        onRefresh();
      },
      onError: () => {
        toast.error("Failed to Send", {
          autoClose: 2000,
          theme: "colored",
        });
      },
    }
  );

  const { mutate: onRevertToPending, isLoading: isRevertToPendingLoading } =
    useMutation(
      (payload: { reason: string }) =>
        NegotiationService.negotiationRevert(data.contract_id, payload),
      {
        onSuccess: () => {
          setBackToNegotiatingModal(false);
          onRefresh();
          toast.success("Successfully Moved", {
            autoClose: 2000,
            theme: "colored",
          });
        },
        onError: () => {
          toast.error("Failed to Move", {
            autoClose: 2000,
            theme: "colored",
          });
        },
      }
    );

  const handleWholesaleValuation = () => {
    setShowWholesaleModal(true);
  };

  const handleOfferModal = () => {
    setShowOfferModal(true);
  };

  const handleInternalModal = () => {
    setShowInternalModal(true);
  };

  const handleMPLogModal = () => {
    setShowMpLogModal(true);
  };

  const handleValuationAddModal = () => {
    setShowValuationAddModal(true);
  };

  const handleOfferAddModal = () => {
    setShowOfferAddModal(true);
  };

  const handleBackToNegotiating = () => {
    setBackToNegotiatingModal(true);
  };

  const handleRequestDisabled = useMemo(() => {
    if (
      STATUS_LIST.SoldAcquisition.findIndex((item) => item.key === name) !== -1
    ) {
      return true;
    } else {
      return false;
    }
  }, [name]);

  if (
    status.name !== "tendered" &&
    VALUATION_INVISIBLE_STATUSES.includes(status.name)
  ) {
    return null;
  }

  const isRetailDealershipFlow = flow === FLOW_TYPE.RETAIL_DEALERSHIP;

  const canViewOfferAmount =
    !isValuationContractType(data) &&
    hasPermission(PermissionCategory.DEALERSHIP_OFFER, PermissionAction.VIEW);

  const canExternalValuationView = hasPermission(
    isValuationContractType(data)
      ? PermissionCategory.WHOLESALE_VALUATIONS
      : PermissionCategory.EXTERNAL_VALUATIONS,
    PermissionAction.VIEW
  );

  const canExternalValuationRequest = hasPermission(
    isValuationContractType(data)
      ? PermissionCategory.WHOLESALE_VALUATIONS
      : PermissionCategory.EXTERNAL_VALUATIONS,
    PermissionAction.REQUEST
  );

  const canMpValuationRequest = hasPermission(
    PermissionCategory.MOTORINTEL_VALUATIONS,
    PermissionAction.REQUEST
  );

  const canMpValuationView = hasPermission(
    PermissionCategory.MOTORINTEL_VALUATIONS,
    PermissionAction.VIEW
  );

  return (
    <div className="section">
      <div className="section__header">
        <span>Valuations</span>
      </div>
      <div className="section__body--valuation valuation">
        {isValuationContractType(data) || canViewOfferAmount ? (
          <FieldValuation
            title="Offer Amount"
            value={offerAmount}
            declined={data.status.name === DECLINED_VALUATION.key}
            onView={handleOfferModal}
            canUserEdit={
              isValuationContractType(data)
                ? hasPermission(
                    PermissionCategory.WHOLESALE_VALUATIONS,
                    PermissionAction.CREATE
                  )
                : hasPermission(
                    PermissionCategory.DEALERSHIP_OFFER,
                    PermissionAction.CREATE
                  )
            }
            onEditValuation={
              name !== ACQUIRED.key ? handleOfferAddModal : undefined
            }
          />
        ) : undefined}
        {hasPermission(
          PermissionCategory.DEALERSHIP_VALUATIONS,
          PermissionAction.VIEW
        ) && (
          <FieldValuation
            title="Internal Valuation (Real)"
            value={valuationAmount}
            onView={handleInternalModal}
            canUserEdit={hasPermission(
              PermissionCategory.DEALERSHIP_VALUATIONS,
              PermissionAction.CREATE
            )}
            onEditValuation={
              name !== ACQUIRED.key ? handleValuationAddModal : undefined
            }
          />
        )}
        {(canMpValuationRequest || canMpValuationView) && (
          <FieldMotorIntelValuation
            rawAmount={valuations?.hub?.offer?.amount}
            hubOfferAmount={hubOfferAmount}
            requested={valuation_requests?.hub || isSuccess}
            isRequesting={isRequestingMPValuation}
            onOpenMPLogModal={handleMPLogModal}
            onRequestMPValuation={handleRequestMPValuation}
            contractId={contract_id}
            vehicleName={data.vehicle.name}
            declined={data.status.name === DECLINED_VALUATION.key}
            isMotorIntelDeclined={!!data.valuation_requests?.hub_declined}
            canUserView={canMpValuationView}
            canRequest={canMpValuationRequest}
          />
        )}
        {(canExternalValuationRequest || canExternalValuationView) && (
          <FieldValuation
            title="Wholesale Partner Valuations"
            tool={
              <LinkButton
                disabled={data.status.name === DECLINED_VALUATION.key}
                onClick={handleWholesaleValuation}
              >
                <span
                  className={classNames("!text-sm", {
                    [`!text-[${DISABLED_STATE_COLOR}]`]:
                      data.status.name === DECLINED_VALUATION.key,
                  })}
                >{`Request/View >`}</span>
              </LinkButton>
            }
          />
        )}
        {/* {isFeatureAvailable(FEATURES.RESEARCH_VEHICLE) && (
              <>
                <div
                  onClick={() => setShowResearchVehicle(!showResearchVehicle)}
                >
                  <FieldValuation
                    title="Research Vehicle"
                    tool={
                      <SvgImage
                        name="SelectDown"
                        className={`${
                          showResearchVehicle ? "transform rotate-180" : ""
                        }`}
                      />
                    }
                  />
                </div>
                {showResearchVehicle && (
                  <div>
                    {reference?.car_sales && (
                      <a
                        className="section__body--link"
                        target="_blank"
                        href={reference?.car_sales}
                        rel="noreferrer"
                      >
                        carsales.com.au
                      </a>
                    )}
                    {reference?.red_book && (
                      <a
                        className="section__body--link"
                        target="_blank"
                        href={reference?.red_book}
                        rel="noreferrer"
                      >
                        redbook.com.au
                      </a>
                    )}
                  </div>
                )}
              </>
            )} */}
        {hasPermission(
          PermissionCategory.PRICE_GUIDE,
          PermissionAction.VIEW_ANY
        ) && (
          <FieldValuation
            title="Pricing Insights"
            tool={
              <LinkButton onClick={() => setShowPricingInsightsModal(true)}>
                <span className={"!text-sm"}>{`View >`}</span>
              </LinkButton>
            }
          />
        )}
      </div>
      {(status.name === DECLINED.key || status.name === ACCEPTED.key) &&
        flow === FLOW_TYPE.RETAIL_DEALERSHIP &&
        canRevertVehicle && (
          <LinkButton
            disabled={isRevertToPendingLoading}
            onClick={handleBackToNegotiating}
            underline
          >
            Move Back To Pending Deal Status
          </LinkButton>
        )}
      <PricingInsightsModal
        data={data?.trade}
        isOpen={showPricingInsightsModal}
        onClose={() => setShowPricingInsightsModal(false)}
        dateCreated={data?.created_at}
      />
      <Modal
        open={showBackToNegotiatingModal}
        size="auto"
        canClose={false}
        closeModal={() => setBackToNegotiatingModal(false)}
      >
        <ModalConfirm
          onCancel={() => setBackToNegotiatingModal(false)}
          onConfirm={() => onRevertToPending({ reason: "Customer cancelled" })}
          isLoading={isRevertToPendingLoading}
        />
      </Modal>
      <Modal
        open={showOfferModal}
        title="Offer Log"
        description="This is the offer amount sent to the person who requested the valuation."
        size="auto"
        closeModal={() => setShowOfferModal(false)}
      >
        <ModalOfferLog
          open={showOfferModal}
          valuationID={valuations.internal?.offer?.valuation_id}
          declined={data.status.name === DECLINED_VALUATION.key}
        />
      </Modal>
      <Modal
        open={showInternalModal}
        title="Internal Valuation (Real)"
        description="This is the estimated actual value of the vehicle, which is only visible to the managers of your dealership."
        size="auto"
        closeModal={() => setShowInternalModal(false)}
      >
        <ModalInternalValuation
          open={showInternalModal}
          valuation_id={valuations.internal?.valuation?.valuation_id!}
        />
      </Modal>
      <Modal
        open={showWholesaleModal}
        title="Wholesale Partner Valuations"
        size="auto"
        closeModal={() => setShowWholesaleModal(false)}
      >
        <ModalWholesaleValuations
          contract_id={contract_id}
          open={showWholesaleModal}
          requestDisabled={
            handleRequestDisabled || !canExternalValuationRequest
          }
          canUserView={canExternalValuationView}
        />
      </Modal>
      <Modal
        open={showMpLogModal}
        size="auto"
        closeModal={() => setShowMpLogModal(false)}
      >
        <ModalMotorIntelLog
          open={showMpLogModal}
          valuation_id={valuations.hub?.offer?.valuation_id!}
        />
      </Modal>
      <Modal
        open={showValuationAddModal}
        size="auto"
        title="Add Valuation Amount"
        closeModal={() => {
          onRefresh();
          setShowValuationAddModal(false);
        }}
      >
        <ModalValuationAdd
          onCloseModal={() => {
            onRefresh();
            setShowValuationAddModal(false);
          }}
          contract_id={contract_id}
          requester_dealership={data.requester_dealership || ""}
          requestID={request_id}
        />
      </Modal>
      <Modal
        open={showOfferAddModal}
        size="auto"
        closeModal={() => {
          onRefresh();
          setShowOfferAddModal(false);
        }}
      >
        <ModalOfferAdd
          onCloseModal={(updated: boolean) => {
            if (updated) {
              navigate(-1);
            } else {
              onRefresh();
            }
            setShowOfferAddModal(false);
          }}
          contractID={contract_id}
          requesterDealershipID={data.requester_dealership || ""}
          requestID={request_id}
        />
      </Modal>
    </div>
  );
};
