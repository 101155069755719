import { useContext } from "react";

import { CardVehicle } from "components/app";

import { AuthContext } from "context/AuthContext";
import { useDealership } from "context/DealershipContext";

import { BUYNOW, FLOW_TYPE } from "utils";
import { PermissionAction, PermissionCategory } from "utils/userPermissions";

import { IVehicleDetail } from "types";

import "./styles.scss";

type ListVehiclesProps = {
  vehicles: IVehicleDetail[];
  onSelect: (
    id: string,
    isExternal: boolean,
    requestId?: string,
    hasSeller?: boolean
  ) => void;
  flow?: FLOW_TYPE;
};

export const ListVehicles = ({
  vehicles,
  onSelect,
  flow,
}: ListVehiclesProps) => {
  const { isDealershipSelectorVisible } = useDealership();
  const { hasPermission } = useContext(AuthContext);

  const showValuedByMP = hasPermission(
    PermissionCategory.MOTORINTEL_VALUATIONS,
    PermissionAction.VIEW
  );

  const canViewDirectOffer = hasPermission(
    PermissionCategory.DIRECT_OFFERS,
    PermissionAction.VIEW
  );

  return (
    <div className="list">
      {vehicles.map((vehicle: IVehicleDetail) => {
        return (
          <CardVehicle
            key={vehicle.contract_id}
            data={vehicle}
            onClick={() =>
              onSelect(
                vehicle.contract_id,
                !vehicle.owner,
                vehicle.request_id,
                vehicle.seller
              )
            }
            isExternal={
              vehicle?.seller?.id !== vehicle.dealership?.id ||
              !!vehicle?.requester_dealership
            }
            showValuedByMP={showValuedByMP}
            flow={flow}
            isDealershipSelectorVisible={isDealershipSelectorVisible}
            disabled={vehicle.status.name === BUYNOW.key && !canViewDirectOffer}
          />
        );
      })}
    </div>
  );
};
